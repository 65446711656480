export const api_key = '2d108b5e-ec42-45cb-a0cf-c5f432ea637a'
export const SPINNER_COLOR = '#558f55';
export const headingColor = '#333333';
export const textColor = 'rgb(92, 92, 92)';
export const screenId = '928969';
export const length = 3;
export const Maxlength = 16;
export const resultMs = 400;

export const LocationUrl = 'https://onecmapp-apis.onecannabismarketinc.com/api/admin/getAllLocations'
