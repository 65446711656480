import axios from 'axios'
import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { ChangeOthersPassEnd } from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import ReactPasswordChecklist from 'react-password-checklist'
import { lowerCaseRegex, minLengthRegex, numberRegex, specialCharRegex, upperCaseRegex } from '../../../constants/regex'
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons
import "./ResetOther.scss"
const ResetOtherPassModal = ({ show, handleClose, data }) => {
  const [spinner, setSpinner] = useState(false)
  const [myPass, setMyPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [validations, setValidations] = useState({
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasMinLength: false,
  });
  const [showMyPass, setShowMyPass] = useState(false); // Toggle for current password visibility
  const [showNewPass, setShowNewPass] = useState(false); // Toggle for new password visibility

  const validatePassword = (newPass) => {
    const hasLowerCase = lowerCaseRegex.test(newPass);
    const hasUpperCase = upperCaseRegex.test(newPass);
    const hasNumber = numberRegex.test(newPass);
    const hasMinLength = minLengthRegex.test(newPass);
    const hasSpecialChar = specialCharRegex.test(newPass); // Check for special character
    setValidations({ hasLowerCase, hasUpperCase, hasNumber, hasMinLength, hasSpecialChar });
  };

  const handleSubmit = async () => {
    setSpinner(true)

    if (myPass === '') {
      Toast('err', `Password can't be empty`)
      setSpinner(false)
      return
    }
    if (newPass === '') {
      Toast('err', ` New password can't be empty`)
      setSpinner(false)
      return
    }
    if (validations.hasLowerCase == false || validations.hasMinLength == false || validations.hasNumber == false || validations.hasUpperCase == false || !validations?.hasSpecialChar) {
      setSpinner(false)
      return
    }

    try {
      const response = await axios.put(
        ChangeOthersPassEnd,
        {
          id: data?._id,
          password: myPass,
          new_password: newPass,
        },
        {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        }
      )

      if (response.status === 200) {
        Toast('success', 'Password updated!')
        handleClose()
        setSpinner(false)
        setMyPass('')
        setNewPass('')
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      setMyPass('')
      setNewPass('')
      handleClose()
      setSpinner(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size='md'>
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Modal.Title style={{ fontSize: '22px' }}>
          Reset Password Of {data?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-3'>
          <div className='plain-input my-3'>
            <label>Current Password</label>
            <br />
            <div style={{ position: 'relative' }}>
              <input
                type={showMyPass ? 'text' : 'password'} // Toggle between password and text
                value={myPass}
                placeholder='Input current password'
                onChange={(e) => setMyPass(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
                autoComplete='new-password'
              />
              <span
                 className='eye-icon'
                onClick={() => setShowMyPass(!showMyPass)} // Toggle visibility
              >
                {showMyPass ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <div className='plain-input my-3'>
            <label>New Password</label>
            <br />
            <div style={{ position: 'relative' }}>
              <input
                type={showNewPass ? 'text' : 'password'} // Toggle between password and text
                placeholder='Minimum length 10 characters'
                value={newPass}
                onChange={(e) => {
                  setNewPass(e.target.value);
                  validatePassword(e.target.value); // Validate on change
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              />
              <span
              className='eye-icon'
                onClick={() => setShowNewPass(!showNewPass)} // Toggle visibility
              >
                {showNewPass ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <ReactPasswordChecklist
            style={{ marginTop: "10px" }}
            rules={["minLength", 'maxLength', "specialChar", "number", "capital", "lowercase"]}
            minLength={10}
            maxLength={32}
            value={newPass}
            messages={{
              minLength: "Password length minimum 10 characters",
              maxLength: "Password length maximum 32 characters",
              specialChar: "Password has special character.",
              number: "Password has number.",
              capital: "Password has a capital letter",
              lowercase: "Password has a lowercase letter",
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='primary-btn-light' onClick={handleClose}>
          Close
        </button>
        <button
          className='primary-btn d-flex justify-content-center align-items-center'
          onClick={handleSubmit}
        >
          Update Password{' '}
          <Spinner
            animation='border'
            size='sm'
            className={spinner ? 'd-block ms-2' : 'd-none ms-2'}
          />
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ResetOtherPassModal
