import { toast } from 'react-toastify';
let toastId = null;

const Toast = (type, msg) => {
  // Check if there's an existing toast
  const existingToastId = toast.isActive('custom-toast');
  
  // // Close any existing toast
  // if (existingToastId) {
  //   toast.update(existingToastId, {
  //     render: msg,
  //     type: type === 'err' ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
  //     autoClose: 3000,
  //   });
  // } else {
  //   // Show the new toast
  //   type === 'err'
  //     ? toast.error(msg, { toastId: 'custom-toast' })
  //     : toast.success(msg, { toastId: 'custom-toast' });
  // }
  if (toastId !== null) {
    toast.dismiss(toastId);
  }

  // Show a new toast and store its id
  toastId = type === 'err'
    ? toast.error(msg, { autoClose: 3000 })
    : toast.success(msg, { autoClose: 3000 });
};

export default Toast;
