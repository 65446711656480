import { Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState, useCallback,useRef } from 'react'
import { Pagination, Spinner, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { parse } from 'uuid'
import plus from '../../assets/icons/plus.svg'
import uploadBtn from '../../assets/icons/upload.svg'
import demoImg from '../../assets/images/demoLogoImg.png'
import DashBoard from '../../components/DashBoard/DashBoard'
import StoreFronManagementModal from '../../components/Modals/StoreFrontManagementModal/StoreFronManagementModal'
import {
    GetAdminProfileUrl,
    GetAllStoreAPI,
    GetAllTagUrl,
    GetAllTypesUrl,
    GetAllCompanyEnd
} from '../../constants/api.constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Roles } from '../../constants/roles'
import detectAdBlock from '../../utils/DetectAdBlocker/DetectAdBlocker'
import Toast from '../../utils/Toast/Toast'
import './CompanyManagement.scss'
import { SPINNER_COLOR } from "../../constants/const"
import CompanyManagementModal from '../../components/Modals/AddCompanyModal/NewCompanyModal'
import { width } from '@fortawesome/free-solid-svg-icons/fa0'
import { SEARCH_REGEX } from '../../constants/regex'
import { debounce } from 'lodash'


const { Option } = Select

const CompanyManagement = () => {
    const [show, setShow] = useState(false)
    const [spin, setSpin] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const history = useHistory()
    const goTocompany = (id) => {
        history.push(`/companyfront/${id}`)
    }

    const HttpStatus = {
        OK: 200,
    };
    const inputRef = useRef(null);

    useEffect(() => {
        if (!spin && inputRef?.current) {
            inputRef?.current?.focus();
        }
    }, [spin]);
    const [totalDoc, setTotalDoc] = useState(0)

    const [allCompany, setAllCompany] = useState([])
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [searchTag, setSearchTag] = useState('')
    const [selectedTag, setSelectedTag] = useState('')
    const [searchType, setSearchType] = useState('')
    const [selectedType, setSelectedType] = useState('')
    const [allTypes, setAllTypes] = useState([])
    const [userData, setUserData] = useState({})

    const [sort, setSort] = useState('')
    useEffect(() => {
        GetUser();
    }, []);

    useEffect(() => {
        loadCompanyData();
        return () => {

        };
    }, [page, userData]);

    const handleInputChange = (e) => {
        const sanitizedValue = e.target.value.replace(SEARCH_REGEX, '');
        setSearch(sanitizedValue);
        setPage(1); //
        debounceFn(sanitizedValue)
        // debounce(loadTicketData, 2000)
        // loadTicketData()
        // debouncedOnChange(sanitizedValue);
    };

    const GetUser = async () => {
        try {
            const response = await axios.get(GetAdminProfileUrl, {
                headers: {
                    menuboard: localStorage.getItem('menu_token'),
                },
            })

            if (response.status === HttpStatus.OK) {
                setUserData(response?.data?.data)
            } else throw new Error(response?.data?.msg)
        } catch (error) {
            Toast('err', error.response?.data?.msg)
        }
    }
    const loadCompanyData = async (tempSearch) => {
        setSpin(true);
        let url = GetAllCompanyEnd + `?page=${page}`;
        if (tempSearch?.length > 0) {
            url += `&filter=${tempSearch}`
            setPage(1)
        } else if (search?.length > 0) {
            url += `&filter=${search}`
            setPage(1)
        }

        try {

            const response = await axios.get(url, {
                headers: {
                    menuboard: localStorage.getItem('menu_token'),
                },
            });
            if (response?.status === 200) {
                setSpin(false);
                const parsedData = (response?.data?.data);
                setAllCompany(parsedData);
                setTotalDoc(response?.data?.total_document);
                // Corrected this line
            } else {
                throw new Error(
                    response?.data?.msg || ' Something wendt wrong! Try again later.'
                );
            }
        } catch (error) {
            setSpin(false);
            Toast(
                'err',
                error.response?.data?.msg || 'Something wentd wrong! Try again later.'
            );
        }
    }



    const debounceFn = useCallback(debounce(loadCompanyData, 500), []);


    let items = []
    let totalPage = 0
    if (totalDoc < 10) totalPage = 1
    else totalPage = Math.ceil(totalDoc / 10)
    for (let number = 1; number <= totalPage; number++) {
        items.push(
            <Pagination.Item key={number} active={number == page}>
                {number}
            </Pagination.Item>
        )
    }


    return (
        <div className='row py-3'>
            {/* <div className='col-3'>
        <DashBoard />
      </div> */}
            <div className='col-12'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='fw-bold'>Company Management</h3>
                    {/* {(userData?.effective_role === Roles.ADMIN || userData?.effective_role === Roles.SUPER_ADMIN) && ( */}
                    <button
                        className='primary-btn d-flex justify-content-center align-items-center '
                        onClick={handleShow}
                    >
                        <img className='me-3' src={plus} alt='' /> Create New Company
                    </button>
                    {/* )} */}

                </div>

                <div className='mt-4 plain-input me-2'>
                    <label htmlFor=''>Search Company</label>
                    <br />
                    <div style={{ position: 'relative' }}>
                        <input
                            type='text'
                            disabled={spin}
                            placeholder='Search by name, email.'
                            onChange={handleInputChange}
                            ref={inputRef}

                        />
                        <FontAwesomeIcon
                            className='font_icon'
                            icon={faSearch}
                        />
                    </div>
                </div>



                {spin && (
                    <>
                        <div className='d-flex justify-content-center align-items-center my-5'>
                            <Spinner animation='border' style={{ color: SPINNER_COLOR }} />
                        </div>
                    </>
                )}

                {!spin && allCompany.length > 0 ? (

                    <Table
                        striped
                        bordered
                        hover
                        responsive
                        borderless={true}
                        className='my-5 text-start'
                    >
                        <thead>
                            <tr>
                                {/* <th>Logo</th> */}
                                <th>Name</th>
                                <th>Owner</th>
                                <th>Email</th>
                                <th>Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!spin && allCompany?.map((dt, idx) => (
                                <tr key={idx} onClick={() => goTocompany(dt?._id)}>
                                    {/* <td><img src={dt.company_logo} className='company_logo' /></td> */}
                                    <td>{dt?.name?.length > 20 ? dt?.name?.slice(0, 20) + '...' : dt?.name}</td>
                                    <td>{dt?.company_name?.length > 20 ? dt?.company_name?.slice(0, 20) + '...' : dt?.company_name}</td>
                                    <td>{dt?.company_email}</td>
                                    <td>{dt?.company_phone}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    !spin && !allCompany.length && (
                        // <h2 className='text-center my-5 text-secondary'>No Data Found!</h2>
                        <></>
                    )
                )}
                {!spin && !allCompany?.length &&
                    <h2 className='text-center my-5 text-secondary'>No Company Found!</h2>

                }
                {(spin || allCompany?.length == 0) || (
                    <div className='d-flex justify-content-center align-items-center my-5'>
                        <Pagination>
                            {items.map((item, index) => (
                                <Pagination.Item
                                    key={index}
                                    active={index + 1 === page}
                                    onClick={() => setPage(index + 1)}
                                    // disabled={index + 1 === page} // Disable the current page
                                    style={{ color: index + 1 === page ?? "rgba(0, 0, 0, 0.6)" }}

                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </div>
                )}
            </div>

            <CompanyManagementModal
                show={show}
                handleClose={handleClose}
                demoImg={demoImg}
                uploadBtn={uploadBtn}
                loadCompanyData={loadCompanyData}
            />
        </div>
    )
}

export default CompanyManagement
