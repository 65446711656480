import axios from 'axios'
import React, { useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { CompanyCreateEnd, FileUploadEnd } from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import { PHONE_REGEX, punctuationRegex, wordBoundaryRegex } from '../../../constants/regex'
import validator from 'validator'
import { length } from '../../../constants/const'
export default function CompanyManagementModal({
  show,
  handleClose,
  demoImg,
  loadCompanyData,
}) {
  const [editSpinner, setEditSpinner] = useState(false)
  const [image, setimage] = useState('')
  const [CompanyData, setCompanyData] = useState({
    name: '',
    company_name: '',
    company_email: '',
    company_phone: "",
  })
  const [photoSpinner, setPhotoSpinner] = useState(false)
  const phoneRegex = PHONE_REGEX;
  const allowedExtensions = ["mp4", "webp", "mkv", "gif"];
  function handleInput(e) {
    const { name, value } = e.target;
  
    let sanitizedValue = value;
  
    if (name === 'name' || name === 'company_name') {
      sanitizedValue = sanitizedValue.replace(wordBoundaryRegex, ' '); 
      sanitizedValue = sanitizedValue.replace(punctuationRegex, '$1 '); 
    }
  
    // Update the state with the sanitized value
    setCompanyData({
      ...CompanyData,
      [name]: sanitizedValue,
    });
  }
  
  
  
  const [tag, setTag] = useState('')

  async function postStoreData() {


    
    if (CompanyData?.name.trim() === '') {
      Toast('err', 'Please enter company name')
      setEditSpinner(false)

      return
    }
    if (CompanyData?.name?.trim().length < length) {
      Toast('err', `Company name must be at least ${length} characters long.`);
      setEditSpinner(false)
      return
    }
    if (CompanyData?.name?.trim().length > 25) {
      Toast('err', `Company name must be  25  characters .`);
      setEditSpinner(false)
      return
    }
    if (CompanyData?.company_name?.trim() === '') {
      Toast('err', 'Please enter company manager name')
      setEditSpinner(false)
      return
    }
    if (CompanyData?.company_name?.trim().length < length) {
      Toast('err', `Company_admin name must be at least ${length} characters long.`);
      setEditSpinner(false)
      return
    }
    if (CompanyData?.company_name?.trim().length > 25) {
      Toast('err', `Company_admin name must be  25 characters.`);
      setEditSpinner(false)
      return
    }
    if (CompanyData?.company_email?.trim() === '') {
      Toast('err', 'Please enter your company email')
      setEditSpinner(false)
      return
    }
    if (!validator.isEmail(CompanyData?.company_email)) {
      Toast('err', 'Please enter a valid email');
      return;
    }
    if (CompanyData?.company_phone?.trim() === '') {
      Toast('err', 'Please enter your company phone')
      setEditSpinner(false)
      return
    }

    if (!phoneRegex.test(CompanyData?.company_phone)) {
      Toast('err', 'Invalid phone number');
      setEditSpinner(false);
      return;
    }
    setEditSpinner(true)


    try {
      await axios
        .post(CompanyCreateEnd, {...CompanyData,company_logo:image}, {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditSpinner(false)
            Toast('success', 'Company Created! Password has been sent via email');

            handleClose();
            loadCompanyData();
            setCompanyData({
              name: '',
              company_name: '',
              company_email: '',
              company_phone: '',
            })
            setimage('')
          } else throw new Error(response?.data?.msg)
        })
    } catch (error) {
      setEditSpinner(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }

 
  const handleCloseReset = () => {
    setCompanyData({
      name: '',
      company_name: '',
      company_email: '',
      company_phone: '',
      company_phone: '',
    })
    setimage('')
  }


  const handleImageUpload = async (e) => {

    if (!e.target.files || e.target.files.length === 0) {
      setimage(null)
      return;
    }
    const file = e.target.files[0];
    
    const formData = new FormData();
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    formData.append('files', file);
    if (allowedExtensions.includes(fileExtension)) {
      Toast('err', 'Invalid image format');
      setimage('')
      return
      
    }
    setPhotoSpinner(true);
    try {
      const res = await axios.post(FileUploadEnd, formData, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      });
      if (res.status === 200) {
        setimage(res?.data?.files[0].path)
        // setCompanyData({
        //   ...CompanyData,
        //   company_logo: res?.data?.files[0].path,
  
        // })
        setPhotoSpinner(false);
        Toast('success', 'Photo uploaded successfully');
      }
    } catch (error) {
      setPhotoSpinner(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => { handleClose(); handleCloseReset() }} size='lg'>
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title style={{ fontSize: '22px' }}>
            Create New Company
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h6>Company Brand Icon / Logo*</h6>
          <div className='d-flex justify-content-start align-items-end'>
            <img
              src={image || demoImg}
              alt='demoImg'
              height='100'
              width='100'
              className='me-4'
            />

            <Form.Group className='' controlId='formBasicEmail'>
              <Form.Label>
                <strong>Image </strong> (aspect ratio should be 1:1. e.g. 512px
                x 512px)
                {photoSpinner && (
                  <Spinner className='ms-1' animation='border' size='sm' />
                )}
              </Form.Label>
              <Form.Control
                type='file'
                onChange={(e) => handleImageUpload(e)}
              />
            </Form.Group>
          </div> */}
          <div className='plain-input my-3'>
            <label for=''>Company Name*</label>
            <br />
            <input
              type='text'
              placeholder='Enter company name'
              value={CompanyData.name}
              onChange={handleInput}
              name='name'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  postStoreData();
                }
              }}
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Company Manager / POC*</label>
            <br />
            <input
              type='text'
              placeholder='Enter name of manager'
              value={CompanyData.company_name}
              onChange={handleInput}
              name='company_name'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  postStoreData();
                }
              }}
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Company Manager Email*</label>
            <br />
            <input
              type='text'
              placeholder='Enter your email'
              value={CompanyData.company_email}
              onChange={handleInput}
              name='company_email'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  postStoreData();
                }
              }}
            />
          </div>


          <div className='plain-input my-3'>
            <label for=''>Company Manager Phone*</label>
            <br />
            <input
              type='text'
              placeholder='+1 (123) 456-7890'
              value={CompanyData.company_phone}
              onChange={(e) => {
                // Remove non-numeric characters except '+'
                const sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
                setCompanyData({ ...CompanyData, company_phone: sanitizedValue });
              }}
              name='company_email'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  postStoreData();
                }
              }}
            />
          </div>



        </Modal.Body>
        <Modal.Footer>
          <button className='primary-btn-light' onClick={handleClose}>
            Close
          </button>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={() => postStoreData()}
            type='submit'
          >
            Save Changes{' '}
            {editSpinner && (
              <Spinner className='ms-2' animation='border' size='sm' />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
