import 'antd/dist/antd.css'
import React, { createContext, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import NavigationBar from '../components/NavigationBar/NavigationBar'
import { ROUTENAME } from '../constants'
import AdManagement from '../pages/AdManagement/AdManagement'
import { AdminManagement } from '../pages/AdminManagement'
import Login from '../pages/Authentication/Login'
import PrivateRoute from '../pages/Authentication/PrivateRoute'
import { ForgotPassReset } from '../pages/ForgotPassReset'
import Home from '../pages/Home/Home'
import StoreHome from '../pages/Home/StoreHome'
import { NotFound } from '../pages/NotFound'
import Profile from '../pages/Profile/Profile'
import { StoreFront } from '../pages/StoreFront'
import TicketPage from '../pages/TicketPage/TicketPage'
import StoreFrontManagement from '../pages/StoreFrontManagement/StoreFrontManagement'
import { ProductListPage } from '../pages/ProductListPage'
// import ProductListPage from '../pages/ProductListPage'
// import { TicketingPanel } from '../pages/TicketingPanel'
import TicketingPanel from '../pages/TicketingPanel/TicketingPanel'
import '../index.scss';
import ThemeManagement from '../pages/ThemeManagement/ThemeManagement'
import { useAuth } from '../Providers/AuthProvider'
import detectAdBlock from '../utils/DetectAdBlocker/DetectAdBlocker'
import SideBar from '../components/CommonLayout/SideBar'
import StoreSideBar from '../components/CommonLayout/StoreSideBar'
import CompanyManagement from '../pages/CompanyManagement/CompanyManagement'
import  CompanyFront  from '../pages/Companyfront/Companyfront'
// import { useRouteMach ,useLocation } from 'react-router-dom';
export const UserContext = createContext()


function App() {
  const { user } = useAuth()

  const isUserLoggedIn = Boolean(localStorage.getItem('menu_token'));

  useEffect(() => {
    detectAdBlock()
  }, [])
  useEffect(()=>{
    const currentRoute = window?.location?.pathname;
  },[window.location.pathname])
  return (
    <Router>
      <NavigationBar />
      <div className='d-flex'>
        <div className='col-3' >
          {isUserLoggedIn && (
            (
              <div >
                {localStorage.getItem('menu_token') ? <SideBar /> : <StoreSideBar />}
              </div>
            )
          )}
        </div>
        <div className='col-9'>
          <div className='container'>
            <Switch>
              {user?._id ? (
                <Redirect from='/login' to={localStorage.getItem('default_path')} />
              ) : (
                <Route exact path={ROUTENAME.LOGIN} component={Login} />
              )}

              <Route
                exact
                path={ROUTENAME.FORGOTPASSRESET}
                component={ForgotPassReset}
              />

              {/* <Route path={ROUTENAME.LOGIN} component={Login} exact /> */}
              <PrivateRoute path={ROUTENAME.STOREHOME} exact>
                <StoreHome />
              </PrivateRoute>
              <PrivateRoute path={ROUTENAME.HOME} exact>
                <Home />
              </PrivateRoute>
              <PrivateRoute exact path={ROUTENAME.COMPANYMANAGEMENT}>
                <CompanyManagement />
              </PrivateRoute>
              <PrivateRoute exact path={ROUTENAME.ADMINMANAGEMENT}>
                <AdminManagement />
              </PrivateRoute>
              <PrivateRoute exact path={ROUTENAME.STOREFRONTMANAGEMENT}>
                <StoreFrontManagement />
              </PrivateRoute>
              <PrivateRoute exact path={ROUTENAME.ADDMANAGEMENT}>
                <AdManagement />
              </PrivateRoute>
              <PrivateRoute exact path={ROUTENAME.STOREFRONT}>
                <StoreFront />
              </PrivateRoute>
              <PrivateRoute exact path={ROUTENAME.COMPANYFRONT}>
                <CompanyFront />
              </PrivateRoute>


              <PrivateRoute exact path={ROUTENAME.TICKETINGPANEL}>
                <TicketingPanel />
              </PrivateRoute>
              <PrivateRoute exact path={ROUTENAME.TICKET}>
                <TicketPage />
              </PrivateRoute>
              <PrivateRoute exact path={ROUTENAME.ProductList}>
                <ProductListPage />
              </PrivateRoute>
              {/* <PrivateRoute exact path={ROUTENAME.GLOBALSETTINGS}>
            <GlobalSettings />
          </PrivateRoute> */}
              <PrivateRoute exact path={ROUTENAME.THEMEMANAGEMENT}>
                <ThemeManagement />
              </PrivateRoute>
              <PrivateRoute exact path={ROUTENAME.PROFILE}>
                <Profile />
              </PrivateRoute>

              <Route path={ROUTENAME.NOTFOUND} component={NotFound} exact />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  )
}

export default App
