import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import {
  GetScreenAdLog
} from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import "./AdReport.scss"

const AdReportModal = ({ show, handleClose, storeId, adId }) => {
    
    const [adReports,setAdReports] = useState([]);
    useEffect(() => {
        loadAdReport()
      }, [])
    const loadAdReport = async()=>{
        const url = GetScreenAdLog + `${storeId}/ad/${adId}`;
        try{
            const res = await axios.get(url,{
                headers: {
                  menuboard: localStorage.getItem('menu_token'),
                },
              });
            if(res.status === 200){
                setAdReports(res.data.data);
            }
        }catch(err){
            Toast('err', err.response?.data?.msg || 'Something went wrong! Try again later.')
        }
    }
    return(
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton style={{ border: 'none' }}>
                <Modal.Title style={{ fontSize: '32px'}}>Ad Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
    {adReports && adReports.length > 0 ? (
        adReports.map((adReport, idx) => (
            <div key={idx} className='d-flex justify-content-start align-items-end ad-report'
            >
                <div style={{ marginInline: '5%' }}>
                    <h2>Screen {idx + 1}</h2>
                    <h6>Unique ID: {adReport._id.unique_id}</h6>
                </div>
                <div className='d-flex flex-row counter-set'  >
                    <div>
                        <h6 className='fw-bold'>Played: &nbsp;</h6>
                        <h6 className='fw-bold'>Count: &nbsp;</h6>
                    </div>
                    <div>
                        <h6 className='fw-normal'>{adReport.total_duration} sec</h6>
                        <h6 className='fw-normal'>
                            {adReport.total_count} &nbsp;
                            {adReport.total_count > 1 ? 'times' : 'time'}
                        </h6>
                    </div>
                </div>
            </div>
        ))
    ) : (
        <div className="text-center">
            <h5>No data found</h5>
        </div>
    )}
</Modal.Body>

        </Modal>
    )
}
export default AdReportModal