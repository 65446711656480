import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import demoImg from '../../../assets/images/demoLogoImg.png'
import { AdCreateEnd, FileUploadEnd, GetAdminProfileUrl, GetScreen } from '../../../constants/api.constants'
import Toast from '../../../utils/Toast/Toast'
import instock from '../../../assets/images/instock.jpg'
import Select from 'react-select';
import "./AddaddModal.scss"
import outstock from '../../../assets/images/stock out-stock.jpg'
import { screenId } from '../../../constants/const'
const AddNewAdModal = ({ show, handleClose, folderId, loadAllFolders, allAds }) => {
  const [photoSpinner, setPhotoSpinner] = useState(false)
  const [photoSpinnerOutofstock, setPhotoSpinnerOutofstock] = useState(false)
  const [photoSpinnerInstock, setPhotoSpinnerInstock] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [photoUrl, setPhotoUrl] = useState('')
  const [productsUrl, setProductsUrl] = useState(false)
  const [photoUrlOutofstock, setPhotoUrlOutofstock] = useState('')
  const [photoUrlInstock, setPhotoUrlInstock] = useState('')
  const [userData, setUserData] = useState({})

  const [data, setData] = useState({
    folder_id: '',
    name: '',
    description: '',
    link: '',
    type: 'photo',
    outofstock: '',
    noStock: '',
    typestock: 'outofstock',
    product: ''
  })
  const HTTP = {
    OK: 200
  }

  const [productData, setProductData] = useState('')
  useEffect(() => {
    GetUser()
  }, [])

  const GetUser = async () => {
    try {
      const response = await axios.get(GetAdminProfileUrl, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        setUserData(response?.data?.data)
      } else throw new Error(response?.data?.msg)
    } catch (error) {
      Toast('err', error.response?.data?.msg)
    }
  }

  useEffect(() => {
    loadProductData();
  }, []);
  const loadProductData = async () => {
    setProductsUrl(true);
    try {
      const response = await axios.get(GetScreen + `/${screenId}` + '/get_products', {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })
      const temp = response?.data?.data?.product;
      setProductData(temp);
      setProductsUrl(false);

    } catch (error) {
      setProductsUrl(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }
  const handleImageUpload = async (e) => {
    setPhotoSpinner(true)
    if (!e.target.files || e.target.files.length === 0) {
      setPhotoSpinner(false); // Stop loader
      setPhotoUrl(null)
      return;
    }
    const file = e.target.files[0]
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    const formData = new FormData()
    formData.append('files', file)
    if (fileExtension !== "webp") {

      try {
        const res = await axios.post(FileUploadEnd, formData, {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        })
        if (res.status === 200) {
          setPhotoUrl(res.data?.files[0]?.path)
          setPhotoSpinner(false)
          Toast('success', 'File uploaded successfully')
        }
      } catch (error) {
        setPhotoSpinner(false)
        setPhotoUrl(null)
      }
    }
    else {
      Toast('err', 'Invalid image format')
      setPhotoSpinner(false)
      setPhotoUrl(null)
      setData({
        ...data,
        link: null, // Set background_image to null in case of an invalid image format
      })
    }
  }
  const handleImageOutofstock = async (e) => {
    setPhotoSpinnerOutofstock(true)
    if (!e.target.files || e.target.files.length === 0) {
      setPhotoSpinnerOutofstock(false); // Stop loader
      setPhotoUrlOutofstock(null)
      return;
    }
    const file = e.target.files[0]
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    const formData = new FormData()
    formData.append('files', file)
    if (fileExtension !== "webp") {

      try {
        const res = await axios.post(FileUploadEnd, formData, {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        })
        if (res.status === HTTP.OK) {
          setPhotoUrlOutofstock(res.data?.files[0]?.path)
          setPhotoSpinnerOutofstock(false)
          Toast('success', 'File uploaded successfully')
        }
      } catch (error) {
        setPhotoSpinnerOutofstock(false)
        setPhotoUrlOutofstock(null)
      }
    }
    else {
      Toast('err', 'Invalid image format')
      setPhotoSpinnerOutofstock(false)
      setPhotoUrlOutofstock(null)
      setData({
        ...data,
        outofstock: null,
      })
    }
  }
  const handleImageInstock = async (e) => {
    setPhotoSpinnerInstock(true);
    if (!e.target.files || e.target.files.length === 0) {
      setPhotoSpinnerInstock(false);
      setPhotoUrlInstock(null)
      return;
    }
    const file = e.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (fileExtension === "webp") {
      Toast('err', 'Invalid image format');
      setPhotoSpinnerInstock(false);
      setPhotoUrlInstock(null);
      return;
    }
    const formData = new FormData();
    formData.append('files', file);
    if (fileExtension) {
      try {
        const res = await axios.post(FileUploadEnd, formData, {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        });
        if (res.status === HTTP.OK) {
          setPhotoUrlInstock(res.data?.files[0]?.path);
          setPhotoSpinnerInstock(false);
          Toast('success', 'File uploaded successfully');
        }
      } catch (error) {
        setPhotoSpinnerInstock(false);
        setPhotoUrlInstock(null);
      }
    } else {
      setPhotoSpinnerInstock(false);
      setData({
        ...data,
        noStock: null, // Set background_image to null in case of an invalid image format
      });
    }
  };

  const handleCloseReset = () => {
    setData({
      folder_id: '',
      name: '',
      description: '',
      link: '',
      type: 'photo',
      outofstock: '',
      noStock: '',
      typestock: 'outofstock',
      product: ''
    });
    setPhotoUrl('')
    setPhotoUrlInstock('')
    setPhotoUrlOutofstock('')
  }
  const handleCreateNewAd = async () => {
    setSpinner(true)
    if (!photoUrl) {
      Toast('err', 'Medial file must be provided')
      setSpinner(false)
      return
    }
    if (data?.typestock == "instock") {
      if (!photoUrlInstock) {
        Toast('err', 'In Stock image must be provided ')
        setSpinner(false)
        return
      }
      if (!photoUrlOutofstock) {
        Toast('err', 'Out of Stock image must be provided  ')
        setSpinner(false)
        return
      }
      if (!data?.product) {
        Toast('err', 'please select product')
        setSpinner(false)
        return
      }

    }
    if (!data?.name) {
      Toast('err', 'Please insert ad name ')
      setSpinner(false)
      return
    }
    if (!data.description) {
      Toast('err', 'Please enter description ')
      setSpinner(false)
      return
    }

    if (data?.typestock == "instock") {

      if (!data?.product) {
        Toast('err', 'please select product')
        setSpinner(false)
        return
      }

    }
    const requestData = {
      folder_id: folderId || '',
      name: data?.name,
      description: data?.description,
      link: photoUrl,
      type: data?.type,
      // Check if typestock is 'outofstock', if so, exclude these fields
      ...(data.typestock === 'notstock' && {
        outofstock: 'photo',
        noStock: 'photo',
        product: ''
      }),
      typestock: data?.typestock,
    };
    try {
      const response = await axios.post(
        AdCreateEnd,
        {
          folder_id: folderId || '',
          name: data?.name,
          description: data?.description,
          link: photoUrl,
          type: data?.type,
          // Check if typestock is 'outofstock', if so, exclude these fields
          ...(data.typestock === 'instock' && {
            outofstock: photoUrlOutofstock,
            noStock: photoUrlInstock,
            product: data?.product
          }),
          typestock: data?.typestock,
          company_id: userData.company_id



        },
        {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        }
      )

      if (response.status === 200) {
        Toast('success', 'AD Created!')
        handleClose()
        setSpinner(false)
        setPhotoUrl(null)
        setData({
          folder_id: '',
          name: '',
          description: '',
          link: '',
          type: 'photo',
        })
        setPhotoUrlInstock('')
        setPhotoUrlOutofstock('')
        if(folderId){

          loadAllFolders(folderId)
        }
        else {
          allAds()

        }
      } else throw new Error(response.data?.msg || 'Try again later')
    } catch (error) {
      setSpinner(false)

      Toast('err', error.response?.data?.msg)
    }
  }

  return (
    <Modal show={show} onHide={() => { handleClose(); handleCloseReset() }} size='lg'>
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Modal.Title style={{ fontSize: '22px' }}>Create New AD</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-start align-items-end'>
          {data?.type === 'photo' ? (
            <img
              src={photoUrl || demoImg}
              alt=''
              height='100'
              width='200'
              className='me-4'
            />
          ) : (
            <div className='mx-3'>
              <ReactPlayer
                url={photoUrl || demoImg}
                width='200px'
                height='100px'
                controls={true}
              />
            </div>
          )}

          <Form.Group className='' controlId='formBasicEmail'>
            <Form.Label>
              {data?.type}*
              {photoSpinner && (
                <Spinner className='ms-1' animation='border' size='sm' />
              )}
            </Form.Label>
            <Form.Control type='file' onChange={(e) => handleImageUpload(e)} />
          </Form.Group>
        </div>
        {
          data?.typestock == "instock" && (
            <>
              <h4 className='m-4'>In  Stock*</h4>
              <div className='d-flex justify-content-start align-items-end'>
                {data?.type === 'photo' ? (
                  <img
                    src={photoUrlInstock || instock}
                    alt=''
                    height='100'
                    width='200'
                    className='me-4'
                  />
                ) : (
                  <div className='mx-3'>
                    <ReactPlayer
                      url={photoUrlInstock || instock}
                      width='200px'
                      height='100px'
                      controls={true}
                    />
                  </div>
                )}

                <Form.Group className='' controlId='formBasicEmail'>
                  <Form.Label>
                    {data?.type}*
                    {photoSpinnerInstock && (
                      <Spinner className='ms-1' animation='border' size='sm' />
                    )}
                  </Form.Label>
                  <Form.Control type='file' onChange={(e) => handleImageInstock(e)} />
                </Form.Group>
              </div>
              <h4 className='m-4'>Out of Stock*</h4>
              <div className='d-flex justify-content-start align-items-end'>
                {data?.type === 'photo' ? (
                  <img
                    src={photoUrlOutofstock || outstock}
                    alt=''
                    height='100'
                    width='200'
                    className='me-4'
                  />
                ) : (
                  <div className='mx-3'>
                    <ReactPlayer
                      url={photoUrlOutofstock || outstock}
                      width='200px'
                      height='100px'
                      controls={true}
                    />
                  </div>
                )}

                <Form.Group className='' controlId='formBasicEmail'>
                  <Form.Label>
                    {data?.type}*
                    {photoSpinnerOutofstock && (
                      <Spinner className='ms-1' animation='border' size='sm' />
                    )}
                  </Form.Label>
                  <Form.Control type='file' onChange={(e) => handleImageOutofstock(e)} />
                </Form.Group>
              </div>
            </>
          )
        }

        <div className='my-3'>
          <div className='plain-input my-3'>
            <label for=''>Ad Name* </label>
            <br />
            <input
              type='text'
              placeholder='Enter name'
              onChange={(e) => setData({ ...data, name: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCreateNewAd();
                }
              }}
            />
          </div>
          <div className='plain-input my-3'>
            <label for=''>Description</label>
            <br />
            <input
              type='text'
              placeholder='Enter details'
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCreateNewAd();
                }
              }}
            />
          </div>

          <div className='plain-dropdown my-3'>
            <label for=''>File Type*</label>
            <br />
            <select
              className=''
              onChange={(e) => setData({ ...data, type: e.target.value })}
            >
              <option value='photo' selected>
                Photo
              </option>
              <option value='video'>Video</option>
            </select>
          </div>
          {
            data?.typestock === "instock" && (
              <div className='plain-dropdown my-3'>
                <label htmlFor=''>Products</label>
                <br />
                {!productsUrl ? (
                  <Select
                    options={productData ? (productData?.flat()?.map(product => ({ value: product?.id, label: product?.name }))) : "No Products"}
                    onChange={(selectedOption) => setData({ ...data, product: selectedOption?.value })}
                    isSearchable
                    placeholder="Select product"
                  />
                ) : (<>  <Spinner
                  animation='border'
                  style={{ color: 'var(--primary_color)' }}
                /></>)}

              </div>
            )
          }


          <div class='plain-dropdown my-3'>
            <label for='' style={{ fontSize: "15px" }}>Select Stock*</label>
            <br />
            <div class="checkbox-container">
              <input
                id="instock-checkbox"
                type="checkbox"
                size={30}
                class="checkbox-input"
                onChange={(e) => setData({ ...data, typestock: e.target.checked ? 'instock' : 'outofstock' })}
              />
              <label for="instock-checkbox" class="checkbox-label">Daily Base</label>
            </div>
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: 'none' }}>
        <button className='primary-btn-light' onClick={() => { handleClose(); handleCloseReset() }}>
          Close
        </button>
        <button
          className='primary-btn'
          onClick={() => handleCreateNewAd()}
          disabled={spinner}
        >
          Save Changes{' '}
          {spinner && <Spinner animation='border' size='sm' className='ms-2' />}
        </button>

      </Modal.Footer>
    </Modal>
  )
}

export default AddNewAdModal
