import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import TicketTags from '../../components/TicketTags/TicketTags'
import plus from '../../assets/icons/plus.svg'
import pcIcon from '../../assets/icons/pc-icon.svg'
import tvIcon from '../../assets/icons/tv-icon.svg'
import mobileIcon from '../../assets/icons/mobile-icon.svg'
import uploadBtn from '../../assets/icons/upload.svg'
import DashBoard from '../../components/DashBoard/DashBoard'
import AddTicketFrontal from '../../components/Modals/AddTicket/AddTicketFrontal'
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { GetAllTasksAPI, GetAllStoresList, GetAdminProfileUrl, GetInfoForOwner } from '../../constants/api.constants'
import axiosInstance from '../../containers/interceptor'

import detectAdBlock from '../../utils/DetectAdBlocker/DetectAdBlocker'
import Toast from '../../utils/Toast/Toast'
import './TicketingPanel.scss'
const Priority = {
  UEGENT: "Urgent",
  HIGH: "High",
  MEDIUM: "Meduim",
  LOW: "Low"
}
const TicketingPanel = () => {
  const [show, setShow] = useState(false)
  const [spin, setSpin] = useState(false)
  const [header, setHeader] = useState('')
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const history = useHistory()
  const [storeList, setStoreList] = useState([])
  const goToTicket = (id) => {
    history.push(`/ticket/${id}`)
  }

  const Roles = {
    TICKETING_STAFF: 'ticketingstaff',
    MANAGER: 'manager',
    ADMIN: 'admin',
    SUPER_ADMIN: "super_admin"
  };

  const filterTicket = event => {
    setSpin(true)
    const value = event.target.value.toLowerCase();
    const filteredTicket = allTickets.filter(ticket => (`${ticket.title}`.toLowerCase().includes(value) || `${ticket.tags}`.toLowerCase().includes(value)));
    setTickets(filteredTicket);
    setSpin(false)
  };
  const [tickets, setTickets] = useState([]);
  const [allTickets, setAllTickets] = useState([]);
  const [sorted, setSorted] = useState({});
  const [userData, setUserData] = useState({})
  const [allInfo, setAllInfo] = useState({})
  const sortByStatus = () => {
    const statusValue = {
      "To-Do": 1,
      "In-Progress": 2,
      "Dev-Done": 3,
      "Testing": 4,
      "Complete": 5,
    }
    const ticketsCopy = [...tickets];
    ticketsCopy.sort((ticketA, ticketB) => {
      if (sorted.reversed) {
        return statusValue[ticketA.status] - statusValue[ticketB.status];
      }
      return statusValue[ticketB.status] - statusValue[ticketA.status];
    });
    setTickets(ticketsCopy);
    setSorted({ sorted: "status", reversed: !sorted.reversed });
  };
  const sortByTitle = () => {
    const ticketsCopy = [...tickets];
    ticketsCopy.sort((ticketA, ticketB) => {
      if (sorted.reversed) {
        return ticketB.status.localeCompare(ticketA.status);
      }
      return ticketA.status.localeCompare(ticketB.status);
    });
    setTickets(ticketsCopy);
    setSorted({ sorted: "title", reversed: !sorted.reversed });
  };
  const sortByDueDate = () => {
    const ticketsCopy = [...tickets];;
    ticketsCopy.sort((ticketA, ticketB) => {
      if (sorted.reversed) {
        return new Date(ticketA.due_date) - new Date(ticketB.due_date);
      }
      return new Date(ticketB.due_date) - new Date(ticketA.due_date);
    })
    setTickets(ticketsCopy);
    setSorted({ sorted: "dueDate", reversed: !sorted.reversed });
  }

  const sortByPriority = () => {
    const priorityValue = {
      Urgent: 4,
      High: 3,
      Medium: 2,
      Low: 1
    }
    const ticketsCopy = [...tickets];
    ticketsCopy.sort((ticketA, ticketB) => {
      if (sorted.reversed) {
        return priorityValue[ticketA.priority] - priorityValue[ticketB.priority]
      }
      return priorityValue[ticketB.priority] - priorityValue[ticketA.priority]
    });
    setTickets(ticketsCopy);
    setSorted({ sorted: "priority", reversed: !sorted.reversed });
  };
  const HttpStatus = {
    OK: 200,
    NOT_FOUND: 404,
    // Add more status codes as needed
  };

  const renderArrow = () => {
    if (sorted.reversed) {
      return <FaArrowUp style={{ marginLeft: "2px" }} />;
    }
    return <FaArrowDown style={{ marginLeft: "2px" }} />;
  };
  // const [searchTag, setSearchTag] = useState('')
  // const [selectedTag, setSelectedTag] = useState('')
  // const [searchType, setSearchType] = useState('')
  // const [selectedType, setSelectedType] = useState('')
  // const [allTypes, setAllTypes] = useState([])
  // const [sort, setSort] = useState('')
  useEffect(() => {
    detectAdBlock();
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      await loadTicketData();

      // Check if userData is available and the effective role is not TICKETING_STAFF
      if (userData?.effective_role !== Roles.TICKETING_STAFF) {
        await loadStoreNames();
      }
    };

    fetchData();
  }, [userData]);

  const loadStoreNames = async () => {
    const url = GetAllStoresList

    try {
      if (localStorage.getItem('menu_token')) {
        const response = await axiosInstance.get(url, {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        });
        if (response.status === HttpStatus.OK) {
          setStoreList(response.data.data)
        }
      }
    } catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }
  const loadAllInfo = async () => {
    try {
      const res = await axios.get(GetInfoForOwner , {
        headers: { menuboard: localStorage.getItem('store_token') },
      })

      if (res.status === HttpStatus.OK) {
        setAllInfo(res?.data?.data[0])
      } else throw new Error(res?.data?.msg)
    } catch (error) {
      Toast('err', error.response?.data?.msg || 'Something went wrong! ')
    }
  }
  const token = localStorage.getItem('menu_token');
  useEffect(() => {
    if (token) {
      GetUser();
    }
  }, []); // Run the effect whenever userData changes
  
  const GetUser = async () => {
    try {
      const response = await axios.get(GetAdminProfileUrl, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      });
  
      if (response.status === HttpStatus.OK) {
        setUserData(response.data.data);
      } else {
        throw new Error(response?.data?.msg);
      }
    } catch (error) {
      Toast('err', error.response?.data?.msg);
    }
  };
  const loadTicketData = async () => {
    let url = GetAllTasksAPI;
    setSpin(true)
    try {
      //This thing should not be here 
      // It should be handled while login 
      // const dummy = await handleToken();
      if (localStorage.getItem('menu_token')) {
        setHeader({
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        });
        const response = await axios.get(url, {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        });

        if (response.status === HttpStatus.OK) {
          const parsedData = JSON.parse(response?.data?.data);

          setAllTickets(parsedData);
          setTickets(parsedData);
          setSpin(false)
        } else {
          throw new Error(
            response?.data?.msg || ' Something went wrong! Try again later.'
          )
        }
      } else {
        setHeader({
          headers: {
            menuboard: localStorage.getItem('store_token'),
          },
        });
        const response = await axios.get(url, {
          headers: {
            menuboard: localStorage.getItem('store_token'),
          },
        });


        if (response.status === 200) {
          const parsedData = response.data.data.reverse();
          setAllTickets(parsedData);
          setTickets(parsedData);
          // setTotalDoc(response?.data?.total_document)
          setSpin(false)
        } else {
          throw new Error(
            response?.data?.msg || ' Something went wrong! Try again later.'
          )
        }
      }

      // if(response.status === 200){
      // } 

    } catch (error) {
      setSpin(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }

  function showTags(tags) {
    return (
      <div className="overflow-container"
      >
        {tags.map((tag, id) => (
          <>
            <TicketTags text={tag} color={"#769FB6"} />
          </>
        ))}
      </div>
    )
  }

  return (
    <div >
      {/* <div className='col-3'>
        <DashBoard />
      </div> */}
      <div className='col-12'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='fw-bold'>Ticketing Panel</h3>
          {userData?.effective_role === Roles.TICKETING_STAFF || (
            <button
              className='primary-btn d-flex justify-content-center align-items-center '
              onClick={handleShow}
            >
              <img className='me-3' src={plus} alt='' /> Create New Ticket
            </button>
          )}

        </div>

        <div className='mt-4 plain-input me-2'>
          <label for=''>Search Ticket</label>
          <br />
          <input
            type='text'
            placeholder='Search by title, tag.'
            onInput={filterTicket}
          // onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* <div className='d-flex justify-content-between align-items-center mt-3'>
          <div className='custom-dropdown '>
            <label for=''>Sort By</label>
            <select onChange={(e) => setSort(e.target.value)}>
              <option hidden>no selected</option>
              <option value='name_ascending'>Name (a to z)</option>
              <option value='name_descending'> Name (z to a)</option>
              <option value='time_descending'>Time (new to old)</option>
              <option value='time_ascending' selected>
                Time(old to new)
              </option>
            </select>
          </div>
          <div className='ms-2 custom-dropdown'>
            <label style={{ marginBottom: '0.7rem' }}>Types</label>
            <br />

            <Select
              className=''
              suffixIcon=''
              style={{ minWidth: '100%' }}
              showSearch
              autoFocus={false}
              optionFilterProp='children'
              onChange={(s) => setSelectedType(s)}
              onSearch={(s) => setSearchType(s)}
              filterOption={false}
            >
              {' '}
              <option value=''>See all</option>
              {allTypes?.map((t, idx) => (
                <option key={idx} value={t?._id?.type}>
                  {t?._id?.type}
                </option>
              ))}
            </Select>
          </div>
          <div className='ms-2 custom-dropdown'>
            <label style={{ marginBottom: '0.7rem' }}>Tags</label>
            <br />

            <Select
              className=''
              suffixIcon=''
              style={{ minWidth: '100%' }}
              showSearch
              autoFocus={false}
              optionFilterProp='children'
              onChange={(s) => setSelectedTag(s)}
              onSearch={(s) => setSearchTag(s)}
              filterOption={false}
            >
              <option value=''>See all</option>
              {allTags?.map((t, idx) => (
                <option key={idx} value={t?._id?.tag}>
                  {t?._id?.tag}
                </option>
              ))}
            </Select>
          </div>
        </div> */}
        {/* {
          tickets.length === 0 && (
            <div>
                  <h3 className='text-center text-muted my-5 py-5'>No Subtask</h3>
            </div>
          )
        } */}
        {spin && (
          <div className='d-flex justify-content-center align-items-center my-5'>
            <Spinner animation='border' style={{ color: '#558f55' }} />
          </div>
        )}

        {tickets.length !== 0 && !spin ? (
          <Table
            striped
            bordered
            hover
            responsive
            borderless={true}
            className='my-5 text-start'
          >
            <thead>
              <tr>
                <th className='text-center'>Title</th>
                <th >Tag</th>
                <th className='text-center ticketPanelHeader' onClick={sortByStatus}>
                  Current Status
                  {sorted.sorted === "status" ? renderArrow() : null}
                </th>
                <th className='text-center ticketPanelHeader' onClick={sortByPriority}>
                  Priority
                  {sorted.sorted === "priority" ? renderArrow() : null}
                </th>
                <th className='text-center ticketPanelHeader' onClick={sortByDueDate}>
                  Due Date
                  {sorted.sorted === "dueDate" ? renderArrow() : null}
                </th>
                <th className='text-center'>Platform</th>
                <th className='text-center'>Assignee</th>
              </tr>
            </thead>
            <tbody>
              {(userData.effective_role === Roles.TICKETING_STAFF) && (tickets?.filter((data) => data.assignee === userData.name).map((dt, idx) => (
                <tr key={idx} onClick={() => goToTicket(dt?._id)}>
                  <td className='text-center align-middle' style={{ width: '200px' }}> {dt.title} </td>
                  <td className='tag-column'>{showTags(dt.tags)}</td>
                  <td className='progress-column'>{showProgress(dt.status)}</td>
                  <td className='priority-column'>{showPriority(dt.priority)}</td>
                  <td className='align-middle' style={{ width: '120px' }}>{showDate(dt.due_date)}</td>
                  <td className='text-center align-middle' style={{ width: '110px' }}>
                    <img
                      className='me-1'
                      src={dt.platform === 'PC' ? pcIcon : dt.platform === 'Mobile' ? mobileIcon : tvIcon}
                      alt=''
                    /> {dt.platform}</td>
                  <td style={{ width: '120px' }} className='align-middle text-center'>
                    {dt.assignee ? (dt.assignee) :
                      ('Not Assigned')
                    }
                  </td>
                </tr>
              )))}


              {(userData.effective_role === Roles.MANAGER) && (tickets?.filter((data) => data.store_id === userData.store).map((dt, idx) => (
                <tr key={idx} onClick={() => goToTicket(dt?._id)}>
                  <td className='text-center align-middle' style={{ width: '200px' }}> {dt.title} </td>
                  <td className='tag-column'>{showTags(dt.tags)}</td>
                  <td className='progress-column'>{showProgress(dt.status)}</td>
                  <td className='priority-column'>{showPriority(dt.priority)}</td>
                  <td className='align-middle' style={{ width: '120px' }}>{showDate(dt.due_date)}</td>
                  <td className='text-center align-middle' style={{ width: '110px' }}>
                    <img
                      className='me-1'
                      src={dt.platform === 'PC' ? pcIcon : dt.platform === 'Mobile' ? mobileIcon : tvIcon}
                      alt=''
                    /> {dt.platform}</td>
                  <td style={{ width: '120px' }} className='align-middle text-center'>
                    {dt.assignee ? (dt.assignee) :
                      ('Not Assigned')
                    }
                  </td>
                </tr>
              )))}
              {(userData.effective_role === Roles.ADMIN || userData.effective_role === Roles.SUPER_ADMIN) && (tickets?.map((dt, idx) => (
                <tr key={idx} onClick={() => goToTicket(dt?._id)}>
                  <td className='text-center align-middle' style={{ width: '200px' }}> {dt.title} </td>
                  <td className='tag-column'>{showTags(dt.tags)}</td>
                  <td className='progress-column'>{showProgress(dt.status)}</td>
                  <td className='priority-column'>{showPriority(dt.priority)}</td>
                  <td className='align-middle' style={{ width: '120px' }}>{showDate(dt.due_date)}</td>
                  <td className='text-center align-middle' style={{ width: '110px' }}>
                    <img
                      className='me-1'
                      src={dt.platform === 'PC' ? pcIcon : dt.platform === 'Mobile' ? mobileIcon : tvIcon}
                      alt=''
                    /> {dt.platform}</td>
                  <td style={{ width: '120px' }} className='align-middle text-center'>
                    {dt.assignee ? (dt.assignee) :
                      ('Not Assigned')
                    }
                  </td>
                </tr>
              )))}
            </tbody>
          </Table>
        ) : (
          !spin && (
            <h2 className='text-center my-5 text-secondary'>No Data Found!</h2>
          )
        )}
      </div>

      <AddTicketFrontal
        show={show}
        storeList={storeList}
        handleClose={handleClose}
        uploadBtn={uploadBtn}
        loadTicketData={loadTicketData}
        header={header}
        userData={userData}
      />
    </div>
  )
}
export function showPriority(priority) {
  let color = "";
  if (priority === Priority.HIGH) color = "#f77f00";
  else if (priority === Priority.UEGENT) color = "#d62828";
  else if (priority === Priority.MEDIUM) color = "#fcbf49";
  else if (priority === Priority.LOW) color = "#eae2b7";
  return (
    <TicketTags text={priority} color={color} />
  )
}

export function showDate(date) {
  date = new Date(date);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "August", "Sep", "Oct", "Nov", "Dec"
  ];
  return (
    <>
      {(date.getDate() + 1) + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear()}
    </>
  )
}
export function showProgress(progress) {
  let color = "";
  if (progress === "In-Progress") color = "#188FA7";
  else if (progress === "To-Do") color = "#769FB6";
  else if (progress === "Dev-Done") color = "#9DBBAE";
  else if (progress === "Testing") color = "#D5D6AA";
  else if (progress === "Complete") color = "#E2DBBE";
  return (
    <TicketTags text={progress} color={color} />
  )
}
export default TicketingPanel
