import axios from 'axios'
import React, { useEffect, useState, useCallback } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { useHistory } from 'react-router-dom'
import Toast from '../../../utils/Toast/Toast'
import { TaskAPI, GetAllStoresList, GetAdminApi, GetAllAdminApi } from '../../../constants/api.constants'
import './EditTicketModal.css';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { debounce } from 'lodash'
import { resultMs } from '../../../constants/const'

function EditTicketModal({ show, handleClose, data, loadTicketData, header, userData }) {
    const history = useHistory()
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteSpinner, setDeleteSpinner] = useState(false);
    const [allAdmins, setAllAdmins] = useState([])
    const [editSpinner, setEditSpinner] = useState(false);
    const [tags, setTags] = useState('');
    const [page, setPage] = useState(1)
    const [storeList, setStoreList] = useState([])
    const [msgErr, setmsgErr] = useState('')
    const [firstTaskCompleted, setFirstTaskCompleted] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [SelectedOptionsPlatform, setSelectedOptionsPlatform] = useState([])

    const [ticketData, setTicketData] = useState({
        title: "",
        description: "",
        tags: [],
        status: "",
        platform: [],
        priority: "",
        due_date: "",
        assignee: "",
    });
    const TicketStatusOptions = ['To-Do', 'In-Progress', 'Dev-Done', 'Testing', 'Complete'];

    const HttpStatus = {
        OK: 200,
    };
    const Roles = {
        TICKETING_STAFF: 'ticketingstaff',
        MANAGER: 'manager',
        ADMIN: 'admin',
        SUPER_ADMIN: "super_admin"
    };
    const platformOptions = [
        { label: 'TV-app', value: 'TV-app' },
        { label: 'Mobile', value: 'Mobile' },
        { label: 'PC', value: 'PC' }
    ];
    const PlatformEnum = {
        ALL: 'All PlatForm',
    };
    const today = new Date().toISOString().split('T')[0];
    const getStoreList = async () => {
        if (localStorage.getItem('menu_token')) {
            try {
                const res = await axios.get(GetAllStoresList, {
                    headers: {
                        menuboard: localStorage.getItem('menu_token'),
                    },
                })
                if (res.status === 200) {
                    setStoreList(res.data.data);
                }
            } catch (err) {
                Toast('err', err.response?.data?.msg || 'Something went wrong! Try again later.'
                )
            }
        }

    }
    useEffect(() => {
        getStoreList();
        const inputDate = new Date(data?.due_date);

        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
        const day = inputDate.getDate().toString().padStart(2, "0");

        const due_date = `${year}-${month}-${day}`;
        // const due_date = new Date(data?.due_date)
        setTicketData({
            title: data?.title,
            description: data?.description,
            tags: data?.tags,
            status: data?.status,
            platform: data.platform,
            priority: data?.priority,
            due_date,
            assignee: data?.assignee
        })
        setmsgErr('')
    }, [data, handleClose]);
    useEffect(() => {
        loadAllAdmin()
    }, [userData])


    // const debounceFn = useCallback(debounce(updateTicketData, resultMs), []);


    // const handleInputChange = () => {
    //     setEditSpinner(true);
    //     debounceFn(ticketData)
    // };
    const loadAllAdmin = async () => {

        let url = `${GetAllAdminApi}`

        // if 
        if (userData?.effective_role) {
            url += '?role=ticketingstaff'
            setPage(1)
        }

        try {
            const response = await axios.get(url, {
                headers: {
                    menuboard: localStorage.getItem('menu_token'),
                },
            })

            if (response.status === HttpStatus.OK) {
                setAllAdmins(response?.data?.data)
            } else
                throw new Error(
                    response?.data?.msg || 'Cant load admins data, try again later'
                )
        } catch (error) {

            // Toast(
            //     'err',
            //     error.response?.data?.msg || 'Cant load admins data, try again later'
            // )
        }

        // useEffect(() => {

        // }, [])

    }


    async function updateTicketData() {
        setFirstTaskCompleted(true);

        if (ticketData.title === '') {
            Toast('err', 'please enter title')
            setFirstTaskCompleted(false)

            setEditSpinner(false)
            return
        }
        if (ticketData.description === '') {
            Toast('err', 'Please enter description')
            setFirstTaskCompleted(false)

            setEditSpinner(false)
            return
        }
        if (ticketData.tags.length === 0) {
            Toast('err', "Please add atleast one tag!")
            setEditSpinner(false)
            setFirstTaskCompleted(false)

            return
        }
        if (ticketData.due_date == "") {
            Toast('err', 'Please select Date and valid Date')
            setEditSpinner(false)
            setFirstTaskCompleted(false)

            return
        }
        if (ticketData.due_date < today) {
            setEditSpinner(false)
            setmsgErr('Due date must be equal to or later than today.');
            setFirstTaskCompleted(false)

            return;
        }
        if (msgErr !== "") {
            setFirstTaskCompleted(false)
            setEditSpinner(false)

            return;
        }
        if (ticketData.platform.length === 0) {
            Toast('err', 'Please select platform')
            setFirstTaskCompleted(false)

            setEditSpinner(false)
            return;
        }
        setEditSpinner(true)
        try {
            await axios
                .put(
                    TaskAPI + `/${data._id}`,
                    ticketData,
                    header
                )
                .then((response) => {
                    if (response.status === 200) {
                        setEditSpinner(false)
                        Toast('success', 'Ticket updated!')
                        handleClose()
                        loadTicketData()
                        setTicketData({
                            title: "",
                            description: "",
                            tags: [],
                            status: "",
                            store_name: "",
                            platform: "",
                            priority: "",
                            due_date: "",
                            assignee: "",
                        })
                    } else throw new Error(response?.data?.msg)
                })
        } catch (error) {
            setEditSpinner(false)

            Toast(
                'err',
                error.response?.data?.msg || 'Something went wrong! Try again later.'
            )
        }
        finally {

            setTimeout(() => {
                // Re-enable the function after some time (e.g., 1 second)
                setFirstTaskCompleted(false)
            }, 3000);
        }
    }


    function handleInput(e) {
        const { name, value } = e.target;
        if (name === 'due_date') {
            const today = new Date().toISOString().split('T')[0];

            if (value < today) {
                setmsgErr('Due date must be equal to or later than today.');
            } else {
                setmsgErr('');
            }
        }
        setTicketData({
            ...ticketData,
            [e.target.name]: e.target.value,
        })
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const enteredTag = event.target.value.trim(); // Trim leading and trailing whitespaces

            if (enteredTag) { // Check if the entered tag is not empty
                if (!ticketData?.tags.includes(enteredTag)) {
                    setTicketData({
                        ...ticketData,
                        tags: [...ticketData.tags, enteredTag],
                    });
                    setTags('');
                }
            }
        }
    }

    const handleDeleteTag = (tags) => {
        let newArr = ticketData?.tags.filter((t) => tags !== t)

        setTicketData({ ...ticketData, tags: newArr })
    }
    const handleDelete = async () => {
        setDeleteSpinner(true)
        try {
            const res = await axios.delete(TaskAPI + `/${data._id}`, header)
            if (res.status === 200) {
                Toast('success', 'Ticket has been deleted successfully')
                setDeleteModal(false)
                setDeleteSpinner(false)
                history.push('/ticketing-panel')
            } else throw new Error(res?.data?.msg)
        } catch (error) {
            Toast('err', error.response?.data?.msg)
            setDeleteModal(false)
            setDeleteSpinner(false)
        }
    }


    useEffect(() => {
        if (ticketData && ticketData.platform) {
            const updatedOptions = ticketData.platform.map(platform => ({
                label: platform,
                value: platform
            }));

            if (updatedOptions.length === 3) {
                updatedOptions.unshift({ label: PlatformEnum.ALL, value: "*" });
            }

            setSelectedOptionsPlatform(updatedOptions);
        }
    }, [ticketData]);

    const handlePlatformChange = (selectedOptions, event) => {
        if (event.option.label === PlatformEnum.ALL && event.action === 'select-option') {
            const updatedOptions = [{ label: PlatformEnum.ALL, value: "*" }, ...platformOptions];
            setSelectedOptionsPlatform(updatedOptions);
            setTicketData({
                ...ticketData,
                platform: updatedOptions.slice(1).map(option => option.value)
            });
        } else if (event.option.label === PlatformEnum.ALL && event.action === 'deselect-option') {
            setSelectedOptionsPlatform([]);
            setTicketData({
                ...ticketData,
                platform: []
            });
        } else if (event.action === "deselect-option") {
            setSelectedOptionsPlatform(selectedOptions.filter((o) => o.value !== "*"));
            setTicketData({
                ...ticketData,
                platform: selectedOptions.filter((o) => o.value !== "*").map(option => option.value)
            });
        } else if (selectedOptions.length === platformOptions.length) {
            const updatedOptions = [{ label: PlatformEnum.ALL, value: "*" }, ...platformOptions];
            setSelectedOptionsPlatform([
                { label: PlatformEnum.ALL, value: "*" },
                ...platformOptions
            ]);
            setTicketData({
                ...ticketData,
                platform: updatedOptions.slice(1).map(option => option.value)
            });
        } else {
            setSelectedOptionsPlatform(selectedOptions);
            setTicketData({
                ...ticketData,
                platform: selectedOptions.map(option => option.value)
            });
        }
    };


    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton style={{ border: 'none' }}>
                    <Modal.Title style={{ fontSize: '22px' }}>Edit Ticket</Modal.Title>
                </Modal.Header>
                {userData?.effective_role === Roles.TICKETING_STAFF || (
                    <Modal.Body>
                        <form onSubmit={updateTicketData}>


                            <div className='plain-input my-3'>
                                <label for=''>Title</label>
                                <br />
                                <input
                                    type='text'
                                    placeholder='Enter title'
                                    value={ticketData.title}
                                    onChange={handleInput}
                                    name='title'
                                />
                            </div>
                            <div className='plain-input my-3'>
                                <label for=''>Description</label>
                                <br />
                                <textarea

                                    className='txt-area'
                                    placeholder='Enter Description'
                                    value={ticketData.description}
                                    onChange={handleInput}
                                    name='description'
                                    onFocus={(e) => {
                                        e.target.style.border = '1px solid rgb(4, 117, 4)';
                                    }}
                                    onBlur={(e) => {
                                        e.target.style.border = '1px solid #afafaf';
                                    }}
                                />
                            </div>
                            <div className='plain-input my-3'>
                                <label for=''>Tags</label>
                                <br />
                                <input
                                    type='text'
                                    placeholder='Enter your tags'
                                    value={tags}
                                    onChange={(e) => setTags(e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                />
                            </div>

                            <div className='d-flex justify-content-start align-items-center flex-wrap'>
                                {ticketData?.tags?.map((tags, idx) => (
                                    <span
                                        key={idx}
                                        className='ticket-tags p-2  me-2 mb-2'

                                    >
                                        {tags}
                                        <TiDelete
                                            className="delete-icon"
                                            onClick={() => handleDeleteTag(tags)}
                                        />
                                    </span>
                                ))}
                            </div>
                            <div className='plain-input my-3 '>
                                <label htmlFor='endDate'>Due Date</label>
                                <br />
                                <input
                                    type='date'
                                    name='due_date'
                                    style={{ textTransform: 'uppercase' }}
                                    onChange={handleInput}
                                    value={ticketData?.due_date}
                                    min={new Date().toISOString().split('T')[0]}
                                    max={new Date(new Date().setFullYear(new Date().getFullYear() + 100)).toISOString().split('T')[0]} // Set max date to 100 years from today
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleKeyDown();
                                        }
                                    }}
                                />
                                {msgErr && <p style={{ color: 'red' }}>{msgErr}</p>}
                                {/* {msgErr && <p style={{ color: 'Ticketeing Panel
red' }}>{msgErr}</p>} */}
                            </div>
                            {/* {localStorage.getItem('menu_token') && (
                        <div className='plain-dropdown  mt-2'>
                            <label for=''>Store Name</label>
                            <select onChange={(e) => {
                                const selectedStoreId = e.target.value.split('|')[0];
                                const selectedStoreName = e.target.value.split('|')[1];
                                setTickets({ ...tickets, store_id: selectedStoreId, store_name: selectedStoreName });
                            }}>
                                <option value="">Select a store</option>

                                {(userData?.effective_role === Roles.TICKETING_STAFF || userData?.effective_role === Roles.MANAGER) ? storeList.filter((data) => data._id === userData?.store).map((store) => {
                                    return (
                                        <option value={`${store._id}|${store.name}`}>
                                            {store.name}
r                                        </option>
                                    )
                                }) : storeList.map((store) => {
                                    return (
                                        <option value={`${store._id}|${store.name}`}>
                                            {store.name}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    )
                    } */}

                            {
                                (userData?.effective_role === Roles.ADMIN || userData?.effective_role === Roles.MANAGER) || (
                                    <div className='plain-dropdown mt-2'>
                                        <label htmlFor=''>Current Status</label>
                                        <select
                                            onChange={(e) => setTicketData({ ...ticketData, status: e.target.value })}
                                            value={ticketData.status}
                                        >
                                            {TicketStatusOptions.map((statusOption) => (
                                                <option key={statusOption} value={statusOption}>
                                                    {statusOption}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )
                            }

                            <div className='plain-dropdown  mt-2'>
                                <label for=''>Priority</label>
                                <select onChange={(e) => setTicketData({ ...ticketData, priority: e.target.value })}>
                                    {/*  urgent, high, low, medium*/}
                                    <option value='Low' selected={ticketData?.priority === 'Low'}>
                                        Low
                                    </option>
                                    <option value='Medium' selected={ticketData?.priority === 'Medium'}>
                                        Medium
                                    </option>
                                    <option value='High' selected={ticketData?.priority === 'High'}>
                                        High
                                    </option>
                                    <option value='Urgent' selected={ticketData?.priority === 'Urgent'}>
                                        Urgent
                                    </option>

                                </select>
                            </div>
                            <div className='plain-dropdown  mt-2'>
                                <label for=''>Platform</label>
                                <div className='Multifield' >
                                    <ReactMultiSelectCheckboxes

                                        value={SelectedOptionsPlatform} // Set the value to ticketData.platform
                                        className="multi-select"
                                        placeholderButtonLabel="Select Platform"
                                        options={[{ label: PlatformEnum.ALL, value: "*" }, ...platformOptions]}
                                        onChange={handlePlatformChange}
                                    />
                                </div>

                            </div>

                            {
                                userData?.effective_role === 'manager' || (
                                    <div className='plain-dropdown mt-2'>
                                        <label htmlFor='assignee'>Assignee</label>
                                        <select
                                            id='assignee'
                                            onChange={(e) => setTicketData({ ...ticketData, assignee: e.target.value })}
                                            value={ticketData.assignee}
                                        >
                                            {/*  urgent, high, low, medium*/}
                                            <option disabled>Select Assignee</option>
                                            {userData?.effective_role === Roles.MANAGER
                                                ? allAdmins
                                                    .filter((data) => data.effective_role === Roles.TICKETING_STAFF)
                                                    .map((dt, idx) => (
                                                        <option key={idx} value={dt.name}>
                                                            {dt.name}
                                                        </option>
                                                    ))
                                                : allAdmins.map((dt, idx) => (
                                                    <option key={idx} value={dt.name}>
                                                        {dt.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                )
                            }


                        </form>
                        {/* </form> */}
                    </Modal.Body>
                )}
                {
                    userData?.effective_role === Roles.TICKETING_STAFF && (
                        <Modal.Body>

                            <div className='plain-dropdown mt-2'>
                                <label htmlFor=''>Current Status</label>
                                <select
                                    onChange={(e) => setTicketData({ ...ticketData, status: e.target.value })}
                                    value={ticketData.status}
                                >
                                    {TicketStatusOptions.map((statusOption) => (
                                        <option key={statusOption} value={statusOption}>
                                            {statusOption}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* </form> */}
                        </Modal.Body>
                    )
                }

                <Modal.Footer>

                    {/* {localStorage.getItem('user_role') === "ADMIN" && ( */}
                    {
                        userData?.effective_role === Roles.TICKETING_STAFF || (
                            <button
                                className='danger-btn-light'
                                onClick={() => {
                                    handleClose()
                                    setDeleteModal(true)
                                }}
                            >
                                Delete
                            </button>
                        )
                    }


                    <button className='primary-btn-light' onClick={handleClose}>
                        Close
                    </button>
                    <button
                        className={`primary-btn d-flex justify-content-center align-items-center ${editSpinner ? 'cursor-null' : ''}`}
                        disabled={disabled || firstTaskCompleted} // Disable if either first task completed or already disabled
                        onClick={updateTicketData}
                    >
                        Save Changes{' '}
                        {editSpinner && (
                            <Spinner className='ms-2' animation='border' size='sm' />
                        )}
                    </button>





                </Modal.Footer>
            </Modal>
            <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
                <Modal.Body>
                    <h4 className='fw-bold text-danger'>Caution!</h4>
                    <h5>Are you sure you want to delete the ticket?</h5>
                </Modal.Body>
                <Modal.Footer style={{ border: 'none' }}>
                    <button
                        className='primary-btn-light'
                        onClick={() => setDeleteModal(false)}

                    >
                        Cancel
                    </button>
                    <button
                        className='primary-btn d-flex justify-content-center align-items-center '
                        onClick={() => handleDelete()}
                        type='submit'
                        disabled={deleteSpinner}
                    >
                        Delete{' '}
                        {deleteSpinner && (
                            <Spinner className='ms-2' animation='border' size='sm' />
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditTicketModal