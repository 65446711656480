import axios from 'axios'
import React, { useEffect, useState, useCallback } from 'react'
import { Alert, Dropdown, Modal, Spinner, Table } from 'react-bootstrap'
import Pagination from 'react-bootstrap/Pagination'
import { useHistory } from 'react-router-dom'
import plus from '../../assets/icons/plus.svg'
import threedot from '../../assets/icons/threedot.svg'
import DashBoard from '../../components/DashBoard/DashBoard'
import { AdminDetailsModal } from '../../components/Modals/AdminDetailsModal'
import { NewAdminModal } from '../../components/Modals/NewAdminModal'
import { ResetOtherPassModal } from '../../components/Modals/ResetOtherPassModal'
import { SEARCH_REGEX } from '../../constants/regex'
import {
  AdminEdit,
  ChangeRole,
  GetAdminApi,
  GetAdminProfileUrl,
  GetAllStoreAPI,
  UserDeleteEnd,
  StoreAllAPI
} from '../../constants/api.constants'
import Toast from '../../utils/Toast/Toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './AdminManagement.scss'
import EditOthersProfileModal from '../../components/Modals/EditOthersProfileModal/EditOthersProfileModal'
import { debounce } from 'lodash'
import { toBeDisabled } from '@testing-library/jest-dom/dist/matchers'
const AdminManagement = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  let history = useHistory()
  const [allAdmins, setAllAdmins] = useState([])
  const [allmanager, setAllManager] = useState([])
  const [alltickingStaff, setAlltickingStaff] = useState([])
  const [allStore, setAllStore] = useState([])
  const [allEditStore, setAllEditStore] = useState([])
  const [store, setSelectedStoreId] = useState('');
  const [searchKey, setSearchKey] = useState('')
  const [page, setPage] = useState(1)
  const [DeleteModal, setDeleteModal] = useState(false)
  const [firstTaskCompleted, setFirstTaskCompleted] = useState(false);

  const [spinner, setSpinner] = useState(true)
  const [loading, setLoading] = useState(true);
  const [totalDoc, setTotalDoc] = useState(0)
  const [role1, setRole] = useState('')
  const [detailsDataModal, setDetailsDataModal] = useState(false)
  const closeDetails = () => setDetailsDataModal(false)
  const [detailsData, setDetailsData] = useState({})
  const [userData, setUserData] = useState({})


  const Roles = {
    TICKETING_STAFF: 'ticketingstaff',
    MANAGER: 'manager',
    ADMIN: 'admin',
    SUPER_ADMIN: "super_admin",
    OWNER: "owner"
  };
  const roles = {
    SUPER_ADMIN: "Super Admin"
  }
  const phoneRegex = /^\+1\d{10}$/;

  useEffect(() => {
    GetUser()
  }, [])
  useEffect(() => {
    loadStoreData()
    loadEditStoreData()
  }, [])

  useEffect(() => {
    

    if (userData?.effective_role === Roles.TICKETING_STAFF) {
      history.push("/")
    }
    if (userData?.effective_role) {
      loadAllAdmin()
      setLoading(false)
    }
  }, [page,role1, userData])

  const loadAllAdmin = async (tempSearch) => {

    let url = GetAdminApi + `?page=${page}`
    if (tempSearch?.length > 0) {
      url += `&filter=${tempSearch}`
    } else if (searchKey.length > 0) {
      url += `&filter=${searchKey}`
    }

    setSpinner(true)
    // if 
    if (userData?.effective_role === 'manager') {
      url += '&role=ticketingstaff';

    } else if (userData?.effective_role === 'admin') {
      url += `&role=${role1}`;


    }
    else if (role1.length > 0) {
      url += `&role=${role1}`;
    }

    try {
      const response = await axios.get(url, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        setAllAdmins(response?.data?.data)
        setTotalDoc(response?.data?.total_document)
        setPage(response?.data?.page)
        setSpinner(false)
      } else
        throw new Error(
          response?.data?.msg || 'Cant load admins data, try again later'
        )
    } catch (error) {
      setSpinner(false)
      // Toast(
      //   'err',
      //   error.response?.data?.msg || 'Cant load admins data, try again later'
      // )
    }
  }

  const GetUser = async () => {
    try {
      const response = await axios.get(GetAdminProfileUrl, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {
        setUserData(response?.data?.data)
      } else throw new Error(response?.data?.msg)
    } catch (error) {
      Toast('err', error.response?.data?.msg)
    }
  }

  const [individualData, setIndividualData] = useState({})
  const [othersProfileModal, setOthersProfileModal] = useState(false)
  const [userRole, setUserRole] = useState('')
  const [userStore, setUserStore] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [editSpinner, setEditSpinner] = useState(false)
  const [deleteSpinner, setDeleteSpinner] = useState(false)
  const [disabled, setDisabled] = useState(false);

  const handleEditOthersModal = (admin) => {
    setIndividualData({
      id: admin._id,
      name: admin.name,
      phone: admin.phone,
      role: admin.effective_role,
      effective_role: admin.effective_role,

    })
    setUserEmail(admin.email)
    setUserStore(admin.store)
    setUserRole({
      effective_role: admin.effective_role,
    })
    setSelectedStoreId(admin.store);
    setOthersProfileModal(true)
  }

  const loadStoreData = async () => {
    let url = GetAllStoreAPI
    try {
      const response = await axios.get(url + '?pagesize=20', {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })


      if (response.status === 200) {
        const parsedData = JSON.parse(response.data.data);
        setAllStore(parsedData);

      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }
  const loadEditStoreData = async () => {
    let url = StoreAllAPI
    try {
      const response = await axios.get(url, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })


      if (response.status === 200) {
        const parsedData = JSON.parse(response?.data?.data);
        setAllEditStore(parsedData);
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }
  const roleMap = {
    'super_admin': 'Super Admin',
    'admin': 'Admin',
    'manager': 'Manager',
    'ticketingstaff': "Ticketing Staff",
    'owner': "Owner"
    // Add more roles and their corresponding labels here
  };
  const debounceFn = useCallback(debounce(loadAllAdmin, 500), []);


  const handleInputChange = (e) => {
    const sanitizedValue = e.target.value.replace(SEARCH_REGEX, '');
    setSearchKey(sanitizedValue);
    setRole(Roles.ADMIN)
    setPage(1); 
    debounceFn(sanitizedValue)
  };



  let debounceTimeout;

  const handleSubmit = async () => {
    setFirstTaskCompleted(true);

    
    // Clear previous debounce timeout
    
    
    
    if (individualData.name === '') {
      Toast('err', 'Please enter your name');
      setFirstTaskCompleted(false)
      setEditSpinner(false);
      return;
    }
    if (individualData.phone === '') {
      Toast('err', 'Please enter your phone');
      setFirstTaskCompleted(false)
      setEditSpinner(false);
      return;
    }
    if (!phoneRegex.test(individualData.phone)) {
      Toast('err', 'Invalid phone number');
      setFirstTaskCompleted(false)
      setEditSpinner(false);
      return;
    }
  
    if (individualData.role === Roles.MANAGER || individualData.role === Roles.TICKETING_STAFF) {
      if (store == "" || store == null) {
        Toast('err', 'Please Select Store');
        setFirstTaskCompleted(false)
        setEditSpinner(false);
        return false;
      }
    }
    
    setEditSpinner(true);
    // Debounce the API call
    // debounceTimeout = setTimeout(async () => {
    //   setEditSpinner(true);
      // if(!editSpinner){
      try {
        const response = await axios.put(
          AdminEdit,
          { ...individualData, store: store },
          {
            headers: {
              menuboard: localStorage.getItem('menu_token'),
            },
          }
        );
  
        if (response.status === 200) {
          Toast("success", `${roleMap[userRole?.effective_role]} updated!`);
          setEditSpinner(false);
          loadAllAdmin();
          setOthersProfileModal(false);
        } else {
          throw new Error(response?.data?.msg || ' Something went wrong! Try again later.');
        }
      } catch (error) {
        setEditSpinner(false);
        setOthersProfileModal(false);
        loadAllAdmin();
        Toast(
          'err',
          error.response?.data?.msg || 'Something went wrong! Try again later.'
        );
      // }
      }
      finally{

        setTimeout(() => {
          // Re-enable the function after some time (e.g., 1 second)
          setFirstTaskCompleted(false)
        }, 3000);
      }
  };
  
  let data;

  const handleDelete = async (id) => {
    setDeleteSpinner(true)

    try {
      const response = await axios.delete(UserDeleteEnd + `?_id=${individualData?.id}`, {
        headers: {
          menuboard: localStorage.getItem('menu_token'),
        },
      })

      if (response.status === 200) {

        Toast("success", `${roleMap[userRole?.effective_role]} deleted!`); // Added a missing comma here
        handleClose();
        setDeleteSpinner(false);
        loadAllAdmin();
        setDeleteModal(false)
        setOthersProfileModal(false);
      }
      else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      handleClose()
      setDeleteSpinner(false)
      setOthersProfileModal(false)
      loadAllAdmin()
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }


  const [showResetPassOther, setShowResetPassOther] = useState(false)
  const [changePassData, setChangePassData] = useState('')
  // pagination start

  let items = []
  let totalPage = 0
  if (totalDoc < 10) totalPage = 1
  else totalPage = Math.ceil(totalDoc / 10)
  for (let number = 1; number <= totalPage; number++) {
    items.push(
      <Pagination.Item key={number} active={number == page}>
        {number}
      </Pagination.Item>
    )
  }
  // pagination end

  return (
    <div className='row py-3'>
      {/* <div className='col-3'>
        <DashBoard />
      </div> */}
      <div className='col-12'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='fw-bold'>User Management</h3>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={handleShow}
          >
            <img className='me-3' src={plus} alt='' /> New User
          </button>
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <div className='custom-input me-2'>
            <label htmlFor=''><b>Search Admin</b> </label>
            <br />
            <div style={{ position: 'relative' }}>
              <input
                type='text'
                id='louytr'
                placeholder='Search by name, email, etc.'
                value={searchKey}
                onChange={handleInputChange}
                autoComplete='new-password' // Set autocomplete to "new-password"
                />

              <FontAwesomeIcon
                icon={faSearch}
                style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
              />
            </div>
          </div>

          <div className='custom-dropdown ms-2'>
            <label for=''>Show</label>
            <select  onChange={(e) => {
              setPage(1)
              setRole(e.target.value)
            }}>
              {userData?.effective_role === "super_admin" && (
                <>
                  <option selected={searchKey} value=''>All Roles</option>
                  <option value='super_admin'>Super Admin </option>
                  <option value='admin'>Admin </option>
                  <option value='manager'>Manager</option>
                  <option value='ticketingstaff'> Ticketing-Staff </option>

                </>
              )}
              {userData?.effective_role === "admin" && (
                <>
                  <option value=''>All Roles</option>
                  <option value='manager'>Manager</option>
                  <option value='ticketingstaff'> ticktingstaff </option>
                </>
              )}
              {userData?.effective_role === "manager" && (
                <>
                  <option value='ticketingstaff'>ticketingstaff</option>

                </>
              )}
            </select>
          </div>
        </div>
        {spinner && (
          <div className='d-flex justify-content-center align-items-center my-5'>
            <Spinner animation='border' style={{ color: '#558f55' }} />
          </div>
        )}
        {!spinner && allAdmins.length > 0 ? (
          <Table
            striped
            bordered
            hover
            responsive
            borderless={true}
            className='my-5 text-start'
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Role</th>
                {/* <th>password</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>

              {(userData?.effective_role === Roles?.MANAGER) && (allAdmins.filter((data) => data?.effective_role == Roles?.TICKETING_STAFF).map((admin, idx) => (
                <tr key={idx}>
                  <td
                    onClick={() => {
                      setDetailsData(admin)
                      setDetailsDataModal(true)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {' '}
                    {admin.name}
                  </td>
                  <td
                    onClick={() => {
                      setDetailsData(admin)
                      setDetailsDataModal(true)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {admin.email}
                  </td>
                  <td
                    onClick={() => {
                      setDetailsData(admin)
                      setDetailsDataModal(true)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {admin.phone}
                  </td>
                  <td
                    onClick={() => {
                      setDetailsData(admin)
                      setDetailsDataModal(true)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {roleMap[admin?.effective_role]}
                  </td>

                  <td className=' text-center'>
                    <Dropdown drop='start' style={{ cursor: 'pointer' }}>
                      <Dropdown.Toggle
                        variant='transparent'
                        id='dropdown-basic'
                      >
                        <img src={threedot} alt='' className='' />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='mt-4'>
                        <Dropdown.Item
                          onClick={() => {
                            handleEditOthersModal(admin)
                          }}
                        >
                          Edit profile
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setShowResetPassOther(true)
                            setChangePassData(admin)
                          }}
                        >
                          reset password
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>


                  </td>
                </tr>)
              ))}

              {(userData.effective_role === Roles.ADMIN) &&
                allAdmins.filter((data) => data.effective_role === Roles.MANAGER || data.effective_role === Roles.TICKETING_STAFF).map((admin, idx) => (
                  <tr key={idx}>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {' '}
                      {admin.name}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {admin.email}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {admin.phone}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {roleMap[admin?.effective_role]}
                    </td>

                    <td className=' text-center'>
                      <Dropdown drop='start' style={{ cursor: 'pointer' }}>
                        <Dropdown.Toggle
                          variant='transparent'
                          id='dropdown-basic'
                        >
                          <img src={threedot} alt='' className='' />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='mt-4'>
                          <Dropdown.Item
                            onClick={() => {
                              handleEditOthersModal(admin)
                            }}
                          >
                            Edit profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setShowResetPassOther(true)
                              setChangePassData(admin)
                            }}
                          >
                            reset password
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                    </td>
                  </tr>
                ))}

              {(userData.effective_role === Roles.SUPER_ADMIN || userData.effective_role === Roles.OWNER) &&
                allAdmins.map((admin, idx) => (
                  <tr key={idx}>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {' '}
                      {admin.name}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {admin.email}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {admin.phone}
                    </td>
                    <td
                      onClick={() => {
                        setDetailsData(admin)
                        setDetailsDataModal(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    >

                      {roleMap[admin?.effective_role]}
                    </td>

                    <td className=' text-center'>
                      <Dropdown drop='start' style={{ cursor: 'pointer' }}>
                        <Dropdown.Toggle
                          variant='transparent'
                          id='dropdown-basic'
                        >
                          <img src={threedot} alt='' className='' />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='mt-4'>
                          <Dropdown.Item
                            onClick={() => {
                              handleEditOthersModal(admin)
                            }}
                          >
                            Edit profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setShowResetPassOther(true)
                              setChangePassData(admin)
                            }}
                          >
                            reset password
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>


                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          !spinner && (
            <h3 className='text-secondary my-5 text-center'>No User Found!</h3>
          )
        )}
        {(totalDoc == '0' || spinner || allAdmins.length == '') || (
          <div className='d-flex justify-content-center align-items-center my-5'>
            <Pagination>
              {items.map((item, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === page}
                  onClick={() => setPage(index + 1)}
                  style={{ color: index + 1 === page ?? "rgba(0, 0, 0, 0.6)" }}

                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        )}

      </div>

      <NewAdminModal
        show={show}
        handleClose={handleClose}
        loadAllAdmin={() => loadAllAdmin()}
        userData={userData}
      />
      <AdminDetailsModal
        show={detailsDataModal}
        handleClose={closeDetails}
        data={detailsData}
        store={allEditStore}
      />
      <EditOthersProfileModal
        show={othersProfileModal}
        handleClose={() => setOthersProfileModal()}
        data={individualData}
        loadAdmin={() => loadAllAdmin()}
      />
      <ResetOtherPassModal
        show={showResetPassOther}
        handleClose={() => setShowResetPassOther()}
        data={changePassData}
      />
      <Modal
        show={othersProfileModal}
        onHide={() => setOthersProfileModal(false)}
        size='md'
      >
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title style={{ fontSize: '22px' }}>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-3'>
            <div className='plain-input my-3'>
              <label for=''>User Name</label>
              <br />
              <input
                type='text'
                placeholder='Enter name'
                value={individualData.name}
                onChange={(e) =>
                  setIndividualData({ ...individualData, name: e.target.value })
                }
              />
            </div>

            <div className='plain-input me-2 mt-2'>
              <label htmlFor=''>Phone</label>
              <br />
              <div className="input-group d-flex">
                <input
                  type='text'
                  value={individualData?.phone}
                  placeholder='+1 (123) 456-7890'
                  onChange={(e) => {
                    // Remove non-numeric characters except '+'
                    const sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
                    setIndividualData({ ...individualData, phone: sanitizedValue });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                  pattern="[0-9]*"
                />
              </div>
            </div>
            <div className='plain-input my-3'>
              <label for=''>Email</label>
              <br />
              <input
                type='text'
                placeholder='Enter email'
                value={userEmail}
                disabled
              />
            </div>
            {individualData?.role !== Roles.SUPER_ADMIN && (
              <div className='plain-dropdown'>
                <label htmlFor=''>Role</label>
                <select
                  onChange={(e) =>
                    setIndividualData({ ...individualData, role: e.target.value })
                  }
                  value={individualData.role}
                  disabled={
                    userData?.effective_role === Roles.SUPER_ADMIN &&
                    individualData.role === Roles.SUPER_ADMIN
                  }
                >
                  <option value='ticketingstaff'>
                    Ticketing-Staff
                  </option>
                  {
                    userData?.effective_role === Roles.MANAGER || (
                      <option value='manager'>
                        Manager
                      </option>
                    )
                  }
                  {
                    userData?.effective_role === Roles.SUPER_ADMIN && (
                      <>
                        <option value='admin'>
                          Admin
                        </option>
                        {
                          individualData?.role === Roles.SUPER_ADMIN && (
                            <option value='super_admin'>
                              Super Admin
                            </option>
                          )
                        }
                      </>
                    )
                  }
                </select>
              </div>
            )}
            {individualData?.role === Roles.SUPER_ADMIN && (
              <div className='plain-input my-3'>
                <label for=''>Role</label>
                <br />
                <input
                  type='text'
                  value={roles.SUPER_ADMIN}
                  disabled={
                    userData?.effective_role === Roles.SUPER_ADMIN &&
                    individualData.role === Roles.SUPER_ADMIN
                  }
                />
              </div>
            )}


            {
              (individualData?.role === "manager" || individualData?.role === "ticketingstaff") && (
                <>
                  <div className='plain-dropdown mt-2'>
                    <label htmlFor='storeSelect'>Store</label>
                    <select
                      id='storeSelect'
                      onChange={(e) => setSelectedStoreId(allEditStore[e.target.value]._id)}
                    >
                      <option value="" hidden>
                        Select Store
                      </option>
                      {
                        userData?.effective_role === "manager" ? (
                          <>
                            {allEditStore
                              .filter((store) => store._id === userData?.store)
                              .map((store) => (
                                <option selected={(store._id === userStore)} key={store._id} value={store}>
                                  {store.name}
                                </option>
                              ))}
                          </>
                        ) :

                          allEditStore.map((store, idx) => (
                            <option selected={(store._id === userStore)} key={idx} value={idx}>
                              {store.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </>
              )
            }



          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            (individualData.role === Roles.SUPER_ADMIN) || (
              <button
                className='danger-btn-light d-flex justify-content-center align-items-center'
                onClick={() => {
                  setOthersProfileModal()
                  setDeleteModal(true)
                }}
              // onClick={() => handleDelete(individualData.id)}
              >
                Delete
                <Spinner
                  className={deleteSpinner ? 'd-block ms-2' : 'd-none ms-2'}
                  animation='border'
                  size='sm'
                />
              </button>
            )
          }

          <button
            className='primary-btn-light'
            onClick={() => setOthersProfileModal(false)}
          >
            Close
          </button>
        
              <button
                className='primary-btn d-flex justify-content-center align-items-center'
                onClick={() => handleSubmit()}
                disabled={disabled || firstTaskCompleted} // Disable if either first task completed or already disabled
                >
                Update Changes{' '}
                <Spinner
                  className={editSpinner ? 'd-block ms-2' : 'd-none ms-2'}
                  animation='border'
                  size='sm'
                />
              </button>
          

        </Modal.Footer>
      </Modal>
      <Modal show={DeleteModal} onHide={() => setDeleteModal(false)}>
        <Modal.Body>
          <h4 className='fw-bold text-danger'>Caution!</h4>
          <h5>Are you sure you want to delete the {individualData?.name}?</h5>
        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}>
          <button
            className='primary-btn-light'
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className='primary-btn d-flex justify-content-center align-items-center '
            onClick={() => handleDelete(individualData?.id)}
            type='submit'
            disabled={deleteSpinner}
          >
            Delete{' '}
            {deleteSpinner && (
              <Spinner className='ms-2' animation='border' size='sm' />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default AdminManagement
