import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { lowerCaseRegex, minLengthRegex, upperCaseRegex, numberRegex, specialCharRegex } from '../../constants/regex'
import { ForgotPassGetOTPEnd, ForgotPassSetPassEnd, ResetForgotPassGetOTPEnd } from '../../constants/api.constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPasswordChecklist from 'react-password-checklist'

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Toast from '../../utils/Toast/Toast'
import "./ForgotPassReset.scss"
const ForgotPassReset = () => {
  const location = useLocation();
  const [spin, setSpin] = useState(false)
  const [email, setEmail] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [countdown, setCountdown] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [OTP, setOTP] = useState('')
  const [pass, setPass] = useState('')
  const Email = location.state?.email || 'No email provided';

  let history = useHistory()
  const HttpStatus = {
    OK: 200,
  };

  const [validations, setValidations] = useState({
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasMinLength: false,
  });

  const validatePassword = (newPass) => {
    const hasLowerCase = lowerCaseRegex.test(newPass);
    const hasUpperCase = upperCaseRegex.test(newPass);
    const hasNumber = numberRegex.test(newPass);
    const hasMinLength = minLengthRegex.test(newPass);
    const hasSpecialChar = specialCharRegex.test(newPass); // Check for special character
    setValidations({ hasLowerCase, hasUpperCase, hasNumber, hasMinLength, hasSpecialChar });
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();  // Add this line
    setSpin(true)

    if (pass === '') {
      Toast('err', 'Password cannot be empty')
      setSpin(false)
      return
    }
    if (OTP === '') {
      Toast('err', 'OTP cannot be empty')
      setSpin(false)
      return
    }
    if (validations.hasLowerCase == false || validations.hasMinLength == false || validations.hasNumber == false || validations.hasUpperCase == false || !validations?.hasSpecialChar) {
      setSpin(false)
      return
    }
    if (pass?.length > 32) {
      setSpin(false)
      return;
    }


    try {
      const response = await axios.put(
        ForgotPassSetPassEnd,
        {
          email: Email,
          otp: OTP,
          password: pass,
        },
        {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        }
      )

      if (response.status === 200) {
        Toast('success', 'New Password Set!')
        setSpin(false)
        setEmail('')
        history.push('/login')
      } else
        throw new Error(
          response?.data?.msg || ' Something went wrong! Try again later.'
        )
    } catch (error) {
      setSpin(false)
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      )
    }
  }
  const handleSubmitResend = async (e) => {
    e.preventDefault();
    try {
      setButtonDisabled(true);

      const response = await axios.put(
        ResetForgotPassGetOTPEnd, // Replace with your actual API endpoint
        { email: Email },
        {
          headers: {
            menuboard: localStorage.getItem('menu_token'),
          },
        }
      );

      if (response.status === HttpStatus.OK) {
        Toast('success', 'OTP has been sent to your email');
      } else {
        throw new Error(
          response?.data?.msg || 'Something went wrong! Try again later.'
        );
      }
    } catch (error) {
      Toast(
        'err',
        error.response?.data?.msg || 'Something went wrong! Try again later.'
      );
    } finally {
      setButtonDisabled(false); // Enable the button regardless of success or failure
    }

    const oldTime = new Date().getTime();
    localStorage.setItem('cdTime', oldTime);

    // You may want to implement a delay using setTimeout if you want to enable the button after 30 seconds
    // setTimeout(() => {
    //   setButtonDisabled(false);
    // }, 30000);
  };
  const sendOtp = async () => {
    if (isButtonDisabled) {
      // Toast('success', 'OTP has been already sent to your email');
    } else {
      try {

        const response = await axios.put(
          ForgotPassGetOTPEnd, // Replace with your actual API endpoint
          { email: Email },
          {
            headers: {
              menuboard: localStorage.getItem('menu_token'),
            },
          }
        );

        if (response.status === HttpStatus.OK) {
          Toast('success', 'OTP has been sent to your email');
        } else {
          throw new Error(
            response?.data?.msg || 'Something went wrong! Try again later.'
          );
        }
      } catch (error) {
        Toast(
          'err',
          error.response?.data?.msg || 'Something went wrong! Try again later.'
        );
      }
    }
  };
  useEffect(() => {
    let timer;

    const oldTImes = localStorage.getItem('cdTime');
    const oldTIme = Math.ceil(oldTImes / 1000);
    const newTimes = new Date().getTime();
    const newTime = Math.ceil(newTimes / 1000);;
    const currenTime = Math.abs(newTime - oldTIme);
    if (currenTime <= 30) {
      setButtonDisabled(true);
      setCountdown(parseInt(currenTime));

    }

    if (isButtonDisabled) {
      // Update countdown every second
      timer = setInterval(() => {
        const newTimes = new Date().getTime();
        const newTime = Math.ceil(newTimes / 1000);;

        const newCd = Math.abs(newTime - oldTIme);
        const newCountdown = newCd >= 30 ? 0 : newCd;
        setCountdown(newCountdown)

      }, 1000);
    }

    // Cleanup interval on component unmount or when countdown reaches 0
    return () => clearInterval(timer);
  }, [isButtonDisabled]);




  useEffect(() => {
    
    // Assuming you have access to the recent path
    const recentPath = history.location.path;

    if (recentPath === '/login') {
      const oldTImes = localStorage.getItem('cdTime');
      const oldTIme = Math.ceil(oldTImes / 1000);
      const newTimes = new Date().getTime();
      const newTime = Math.ceil(newTimes / 1000);
      const currenTime = Math.abs(newTime - oldTIme);

      if (currenTime >= 30) {
        sendOtp();
      }

    } else {
      // Reset the flag or perform other actions if needed
    }

    // Clean up the listener when the component unmounts
    // Note: You may want to uncomment this if you are using a listener
    // return () => unlisten();

  }, [history]);

  useEffect(() => {
    if (countdown === 0) {
      setButtonDisabled(false);
    }
  }, [countdown]);


  return (
    <div className='login-card1 '>
      <h2 className='text-center fw-bold'>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <div className='plain-input mt-3 '>
          <label for=''>Email</label>
          <br />
          <input
            type='text'
            placeholder='Enter your email'
            value={Email}
            disabled
          />
        </div>
        <div className='plain-input mt-3 '>
          <label for=''>OTP</label>
          <br />
          <input
            type='text'
            placeholder='Enter your OTP'
            onChange={(e) => setOTP(e.target.value)}
            value={OTP}
            autoComplete='new-password' // Set autocomplete to "new-password"

          />
        </div>
        <div className='plain-input mt-3'>
          <label htmlFor='password'>New Password</label>
          <br />
          <div style={{ position: 'relative' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='Enter your password'
              onChange={(e) => {
                setPass(e.target.value)
                validatePassword(e.target.value); // Change to newPass
              }}
              autoComplete='new-password' // Set autocomplete to "new-password"

              value={pass}
            />
            <button
              type='button'
              onClick={() => setShowPassword(!showPassword)}
              className='eye-btn'
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </button>
          </div>
        </div>
        <ReactPasswordChecklist
          style={{ marginTop: "10px" }}
          rules={["minLength", 'maxLength', "specialChar", "number", "capital", "lowercase"]}
          minLength={10}
          maxLength={32}
          value={pass}
          // valueAgain={passwordAgain}
          messages={{
            minLength: "Password length minimum 10 characters",
            maxLength: "Password length maximum 32 characters",
            specialChar: "Password has special character.",
            number: "Password has number.",
            capital: "Password has a capital letter",
            lowercase: "Password has a lowercase letter",
              
          }}
        />
        <button
          className='primary-btn w-100 my-4 d-flex justify-content-center align-items-center'
          onClick={handleSubmitResend}
          type='button'
          disabled={isButtonDisabled}
          style={{ backgroundColor: isButtonDisabled && '#f8f8f8', color: isButtonDisabled && "black" }}
        >
          {isButtonDisabled ? `Resend OTP in ${Math.abs(countdown - 30)} seconds` : 'Resend OTP'}

        </button>

        <button
          className='primary-btn w-100 my-4 d-flex justify-content-center align-items-center'
          type='submit'
        >
          Reset Password{' '}
          <Spinner
            animation='border'
            size='sm'
            className={spin ? 'd-block ms-2' : 'd-none ms-2'}
          />
        </button>

      </form >
    </div >
  )

}
export default ForgotPassReset
